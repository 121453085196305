import React from "react";
import {
  Jumbotron, Row,
  Col, Container
} from "react-bootstrap";
import {
  jumbotronHighlight, highlights,
  highlightImg, highlightTitle, highlightSubtitle
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";

const ProductHighlights = ({ data }) => {
  const { title, highlight } = data;

  return (
    <Container>
      <Jumbotron className={jumbotronHighlight}>
        <h2>{title}</h2>
        <Row>
          {highlight?.map((h) => {
            return (
              <Col lg={highlight.length === 3 ? 4 : 3} xs={6} key={h._key}>
                <div className={highlights}>
                  <img className={highlightImg} srcSet={getImagePath(h.image, true)} alt={h.image?.alt}/>
                  <p className={highlightTitle}>{h.title}</p>
                  {h.Subtitle ?   <p className={highlightSubtitle}>{h.Subtitle}</p> : ""}
                </div>
              </Col>
            );
          })}
        </Row>
      </Jumbotron>
    </Container>
  );
};

export default ProductHighlights;
